<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 5 9"
  >
    <path
      fill="currentColor"
      d="M4.8 4.2a.5.5 0 0 0-.7 0L0 8v.7c.3.2.6.2.8 0L5 5v-.7ZM.2.2C0 .3 0 .6.2.8l2.5 2.6a1 1 0 0 0 1.5 0L1 0a.5.5 0 0 0-.7 0Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'ArrowRightIcon'
  }
</script>
